body{
    margin: 0;
    background-color: #e1e1e1;
}

.main{
    width: 1500px;
    margin: 0 auto;
    background-color: white;
    padding-bottom: 30px;
}

.main > img{
    width: 100%; 
}

.main{
    margin-top: 104px;
}

.btn{
    text-decoration: none;
    border: none;
    color: white;
    background-color: rgb(171, 53, 64);
    padding: 7px;
    display: inline-block;
    margin: 20px 10px;
    border-radius: 3px;
    cursor: pointer;
}

.btn-center{
    display: block;
    width: fit-content;
    margin: 10px auto;
}

.actif{
    background-color: rgb(36, 86, 130);
}

.dp-none{
    display: none;
}

.btn-filtre{
    border-width: 2px;
    border-color: buttonborder;
    border-image: initial;
}

#afficher-filtre{
    display: none;
}

.btn-del{
    background: none;
    border: none;
}

.btn-mdp{
    padding: 5px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #dddddd;
    text-decoration: none;
    color: black;
    display: block;
    width: fit-content;
    margin: 30px;
}

.active{
    border-style: inset;
    background-color: #7e2b33;
}

.btn-header{
    font-size: 1.3rem;
}

.btn-principale{
    padding: 15px;
    align-self: center;
}

.lien-responsive{
    display: none;
}

.lien{
    text-decoration: none;
    color: #ab3540;
    cursor: pointer;
}

.lien-header{
    padding: 7px;
    font-size: 1.3rem;
}

.panier-mobile{
    display: none;
}

.lien:hover{
    color: black;
}

.lien-responsive{
    background: none;
    border: none;
    margin: 0 2%;
}

.lien-admin{
    display: none;
}

.center{
    text-align: center;
}

.msg-reset-password{
    padding-top: 30px;
    margin: 0 50px;
}

nav .btn{
    margin: 0;
}

.p-relative{
    position: relative;
}

.carousel-inner{
    margin: 10px;
    overflow: auto;
    padding-bottom: 10px;
}

.carousel-inner::-webkit-scrollbar {
    height: 5px;
    border-radius: 8px;
    margin: 5px 0;
    background-color: #ab35418a;

}

.carousel-inner::-webkit-scrollbar-thumb{
    background-color: #ab3540;
    border-radius: 8px;
}

.carousel-control-prev, .carousel-control-next{
    background: none;
    cursor: pointer;
    height: 30px;
    width: 30px;
    border: none;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    color: #ab3540;

}

.carousel-control-next{
    right: -20px;
}

.pagination-prev{
    rotate: 180deg;
}

.carousel-control-prev{
    left: -20px;
    rotate: 180deg;
}

.carousel-inner .carte-carousel{
    box-shadow:  1px 6px 10px rgb(155 155 155 / 71%);
    border-radius: 8px;
    margin: 5px;
    padding: 10px;
    width: 300px;
}

.carte-carousel > img{
    width: 100px;
    margin: 0 80px;
}

.fond-carte{
    background-color: rgb(206 206 206 / 50%);
    margin: 1px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fond-carte-A{
    background-color: #28a745;
}

.fond-carte-B{
    background-color: #84a728;
}

.fond-carte-C{
    background-color: #8ece33;
}

.fond-carte-D{
    background-color: #ffc107;
}

.fond-carte-E{
    background-color: #ffae07;
}

.fond-carte-F{
    background-color: #ff7407;
}

.fond-carte-G{
    background-color: #dc3545;
}

.en-stock{
    color: green;
}

.rupture{
    color: red;
}

.carousel-vide{
    display: block;
}

.carousel-non-vide{
    display: none;
}

.liste-pneus{
    padding: 0 20px;
}

.liste-pneus .filtre-boutique{
    width: 400px;
    box-shadow: 1px 6px 10px rgb(155 155 155 / 71%);
    margin-right: 10px;
    border-radius: 7px;
}

.carte-boutique{
    box-shadow:  1px 6px 10px rgb(155 155 155 / 71%);
    border-radius: 8px;
    margin: 20px 0;
    padding: 5px 0;
    width: 100%;
}

.carte-boutique div{
    margin: 0 20px;
}

.carte-boutique .img-carte{
    max-width: fit-content;
}

.carte-boutique img{
    width: 100px;
    margin: 0 20px;
}

.carte-boutique .saison{
    margin: 10px 0;
    text-align: left;
}

.carte-boutique .score-energie{
    margin: 10px 0;
    width: 120px;
}

.carte-boutique .fond-carte{
    width: 40%;
    margin: 0;
    padding: 0 5px;
}

.carte-boutique .info-pneu{
    text-align: left;
}

.carte-boutique .info-pneu div span{
    background-color: rgb(113 113 113);
    padding: 5px;
    color: white;
    margin-right: 20px;
}

.carte-boutique .prix{
    max-width: fit-content;
}

.fleche{
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 16px 16px 16px 0;
    border-color: transparent rgb(206 206 206 / 50%) transparent transparent;
}

.fleche-A{
    border-color: transparent #28a745 transparent transparent;
}

.fleche-A{
    border-color: transparent #28a745 transparent transparent;
}

.fleche-B{
    border-color: transparent #84a728 transparent transparent;
}

.fleche-C{
    border-color: transparent #8ece33 transparent transparent;
}

.fleche-D{
    border-color: transparent #ffc107 transparent transparent;
}

.fleche-E{
    border-color: transparent #ffae07 transparent transparent;
}

.fleche-F{
    border-color: transparent #ff7407 transparent transparent;
}

.fleche-G{
    border-color: transparent #dc3545 transparent transparent;
}

.carte-boutique .btn{
    min-width: fit-content;
}

.flex{
    display: flex;
    text-align: center;
    align-items: center;
}

.f-start{
    align-items: flex-start;
}

.f-stretch{
    align-items: stretch;
}

.align-baseline{
    align-items: baseline;
}

.flex div{
    width: 100%;
}

.flex span{
    display: block;
}

.filtre-saisons button{
    width: 100%;
}

nav > .flex{
    margin-right: 40px;
}

h1{
    text-align: center;
    margin: 0;
    padding: 24px 0;
    background-color: #ff040417;
}

h2{
    color: black;
}

i{
    margin: 0 5px;
}

.fa-snowflake{
    color: #002970;
}

.fa-sun{
    color: #ffc107;
}

.fa-circle-check{
    color: #28a745;
}

.fa-circle-xmark{
    color: #ff1a1a;
}

.fa-facebook{
    font-size: 4rem;
    color: #245682;
}

select{
    height: 30px;
    width: 70px;
}

.nav-header, .nav-footer{
    justify-content: space-between;
    background-color: white;
}

.nav-header{
    box-shadow: 2px -5px 16px 3px black;
    width: 100%;
    height: 104px;
    position: fixed;
    top: 0;
    align-items: center;
    z-index: 10;
}

.nav-header .menu-user{
    width: auto;
    justify-content: space-between;
}

.menu-user a{
    margin: 0 10px;
}

.nav-header .menu-admin{
    display: flex;
    width: auto;
}

.menu-admin{
    position: relative;
    width: auto;
    padding: 20px;
}

.menu-admin div{
    display: none;
    flex-direction: column;
    position: absolute;
    background-color: white;
    width: 300px;
    top: 110px;
    left: -30px;
}

.menu-admin div a{
    padding: 10px;
}

.menu-admin:hover div, .menu-admin div:hover{
    display: flex;
}

.centrer{
    text-align: center;
}

.banniere{
    position: relative;
}

.banniere > div{
    display: flex;
    overflow: hidden;
}

.img-banniere{
    width: 1500px;
}

.apercu-image{
    width: 300px;
}

.apercu-image-actuel{
    width: 50%;
    margin: 0 auto;
}

.btn-centrer{
    display: block;
    width: 150px;
    margin: 10px auto;
    text-align: center;
}

.recherche{
    top: 0;
    width: 100%;
    align-items: center;
    position: absolute;
    flex-direction: column;
}

.recherche .filtre-pneus{
    background-color: white;
    width: 600px;
    border: solid 1px;
    border-radius: 30px;
    align-items: center;
    display: none;
    position: relative;
    padding: 0 20px;
}

.filtre-pneus img{
    margin: 20px 0;
}

.filtre-pneus form{
    align-items: center;
}

.filtre-pneus span{
    position: absolute;
    color: white;
}

#spanlargeur{
    top: 55px;
    left: 38%;
    rotate: -37deg;
}

#spanslash{
    top: 43px;
    left: 42%;
    rotate: -41deg;
}

#spanhauteur{
    top: 39px;
    left: 43%;
    rotate: -16deg;
}

#spanR{
    top: 34px;
    left: 47%;
}

#spandiametre{
    top: 33px;
    left: 49%;
}

#spancharge{
    top: 39px;
    left: 54%;
    rotate: 21deg;
}

#spanvitesse{
    top: 50px;
    left: 58%;
    rotate: 35deg;
}

.montage{
    align-items: center;
}

.montage img{
    height: 340px;

}

.montage div{
    padding: 0 30px;
}

.categories{
    overflow-y: auto;
    margin-bottom: 5px;
}

.categories h3{
    width: 100%;
    margin: 0;
}

.categories div{
    border: 1px #082128 solid;
    margin: 10px;
    border-radius: 5px;
}

.categories::-webkit-scrollbar {
    height: 5px;
    border-radius: 8px;
    margin: 5px 0;
    background-color: #ab35418a;
}

.categories::-webkit-scrollbar-thumb{
    background-color: #ab3540;
    border-radius: 8px;
}

.explication{
    margin: 20px 0;
}

.explication img{
    width: 200px;
}

.explication h3{
    padding: 0 20px;
}

.horaire-adresse{
    align-items: flex-start;
}

.horaire-adresse span{
    margin: 10px 0;
}

.ouverture h3{
    padding-left: 80px;
}

.jours-ouverture, .heures-ouverture{
    text-wrap: nowrap;
    padding: 0 20px;
}

.jours-ouverture{
    text-align: right;
}

.heures-ouverture{
    text-align: left;
}

.filtre-boutique{
    position: relative;
    width: 40%;
    margin: 20px auto;
    padding: 10px;
    align-items: center;
}

.filtre-boutique .filtre-marques, .filtre-boutique .filtre-options{
    width: 70%;
    justify-content: space-between;
    margin: 10px 55px;
}

.filtre-boutique .filtre-prix{
    justify-content: space-between;
    margin: 20px 0 0;
}

.filtre-boutique .filtre-prix input{
    width: 80px;
    text-align: center;
}

.achat-occasion p{
    text-align: center;
}

.box-forfait{
    border: solid red 3px;
    margin: 20px;
    padding: 20px;
    border-radius: 5px;
}

.mapaccueil{
    width: 800px;
    height: 376px;
}

#mapcontact{
    width: 800px;
    height: 500px;
}

table, tr, td, th{
    border-collapse: collapse;
    padding: 0;
    border-bottom: rgb(135 135 135 / 56%) solid 1px;
}


.panier tr{
    height: 50px;
}

.panier .th-image{
    width: 10%;
}

.panier .th-description{
    width: 40%;
}

.panier .th-prix-ht{
    width: 10%;    
}

.panier .th-prix-ttc{
    width: 10%;    
}

.panier .th-quantite{
    width: 10%;    
}

.panier .th-total-ht{  
    width: 10%;    
}

.panier .th-total-ttc{
    width: 10%;  
}

.panier img{
    margin: 0 30px;
    height: 200px;
    width: auto;
}

.panier input{
    width: 40px;
    height: 20px;
}

.total-panier{
    width: 30%;
    border-left: rgb(135 135 135 / 56%) solid 1px;
}

.total-panier tr{
    height: 50px;
}

.flex-panier{
    justify-content: flex-end;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-end;
}

.commande-gestion, .user-gestion{
    width: 100%;
    text-align: center;
    margin: 30px 0;
    overflow-y: auto;
}

.commande-gestion td, .user-gestion td{
    padding: 20px 10px;
}

.commande-gestion .btn, .user-gestion .btn{
    margin: 0;
}

.commande-gestion .adresse{
    width: 20%;
}

form ul{
    margin: 5px 0;
    padding: 0;
}

form li{
    list-style-type: none;
    color: red;
}

.form-1 div ,.form-ligne div{
    display: flex;
    flex-direction: column;
    margin: 10px 30px;
}

.form-ligne{
    align-items: end;
}

.form-1 label, .form-ligne label{
    text-align: left;
}

.form-1 .form-checkbox{
    flex-direction: row;
    position: relative;
}

input[type='checkbox'], input[type='radio']{
    position: absolute;
    right: 15px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
}

input[type='radio']{
    left: 15px;
    border-radius: 50%;
}

.check{
    position: absolute;
    right: 15px;
    height: 20px;
    width: 20px;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.check-banniere{
    left: 30px;
}

.label-banniere{
    margin: 0 30px;
}

.radio-check{
    left: 15px;
    border-radius: 50%;
}

.RGPD{
    margin-left: 60px;
}

.filtre-options:hover .check, .filtre-marques:hover .check, .form-checkbox:hover .check, .input-radio:hover .check{
    background-color: rgba(171, 53, 65, 0.63);
}

input[type='checkbox']:checked ~ .check, input[type='radio']:checked ~ .check{
    background-color: rgb(171, 53, 65);
}

.form-checkbox .check, .form-checkbox input[type='checkbox']{
    left: 0;
}

.form-radio, .form-radio div{
    display: flex;
    margin: 10px 15px;
}

.form-radio{
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.form-radio div{
    margin-bottom: 0;
}

.form-radio div label{
    margin-left: 30px;
}


.form-text{
    padding: 5px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #dddddd;
    width: 100%;
}

.form-text-1{
    width: 94%;
}

.form{
    width: 50%;
    margin: 0 auto;
    text-align: center;
}

.form-chambre{
    width: 50%;
    margin: 0 auto;
}

.commande .form-commande{
    width: 60%; 
    margin: 0 20px;
}

.pagination{
    max-width: 310px;
    margin: 0 auto;
}

.pagination ul{
    justify-content: space-between;
}

.pagination li{
    list-style-type: none;
    width: 32px;
}

.pagination span{
    border-radius: 50%;
}

.pagination .actif{
    background-color: #e59191;
    padding: 7px 0;
}

.form-commande .form-1 div, .form-commande .form-ligne div{
    margin: 10px 15px;
}

.commande .panier, .commande .total-panier{
    border-right:  rgb(135 135 135 / 56%) solid 1px;
}

.commande .panier tr:first-child{
    border-top:  rgb(135 135 135 / 56%) solid 1px;
}

.erreur{
    color: red;
    margin-top: 5px;
    display: block;
}

.creer-compte{
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.nav-footer{
    padding-bottom: 30px;
    box-shadow: 2px 5px 16px 3px black;
    align-items: baseline;
}

.nav-footer h3{
    border-bottom: 1px black solid;
}

.contact-footer{
    margin: 0 20px;
}

.contact-footer > div{
    justify-content: space-around;
}


.navigation-footer a{
    display: block;
    margin: 5px 0;
}

.Paiement-footer img{
    width: 300px;
}

/* Responsive */
@media screen and (max-width: 1500px) {
    .main{
        width: 100%;
    }

    .carousel-control-prev{
        left: 10px;
    }

    .carousel-control-next{
        right: 10px;
    }

    .img-banniere{
        width: 100vw;
    }

    .panier-fullscreen{
        display: none;
    }

    .panier-mobile{
        display: block;
        position: absolute;
        right: 80px;
    }

    .nav-header .menu-admin{
        display: none;
    }

    .lien-admin{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav-header .bt{
        border-top: black 1px solid;
    }

    .nav-header .menu-user{
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-end;
        align-items: end;
        background-color: white;
        position: absolute;
        right: 0;
        top: 104px;
        margin: 0;
        width: 100%;
    }

    nav .menu-user .lien{
        border-bottom: black 1px solid;
        width: 95%;
    }

    nav .menu-user .btn{
        margin: 5px 0;
        width: 95%;
    }

    nav .lien-responsive{
        display: block;
    }
}

@media screen and (max-width: 1200px) {
    .carte-boutique{
        flex-direction: column;
    }

    .carte-boutique .info-pneu{
        text-align: center;
    }

    .filtre-boutique{
        width: 60%;
    }
}

@media screen and (max-width: 1200px) {
    .commande .prix-unitaire{
        display: none;
    }
    .categories{
        width: 100% !important;
    }

    .forfait div{
        margin: 10px;
        padding: 10px;
    }

    .forfait div div{
        margin: 0;
        padding: 0;
    }
}

@media screen and (max-width: 1100px) {
    .forfait div{
        padding: 5px 0;
    }

    .commande{
        flex-direction: column;
    }

    .commande .form-commande{
        width: 90%;
        margin: auto;
    }
}

@media screen and (max-width: 900px) {
    .commande-gestion, .user-gestion {
        width: 90%;
        margin: auto;
        border: none;
    }

    .commande-gestion tbody, .user-gestion tbody {
        padding-bottom: 30px;
    }

    .commande-gestion > tbody > tr > td[data-titre]:before, .user-gestion > tbody > tr > td[data-titre]:before {
        content: attr(data-titre);
        float: left;
    }

    .commande-gestion > tbody > tr > td, .user-gestion > tbody > tr > td {
        display: block;
        text-align: right;
        padding: 20px;
        border: none;
    }

    .commande-gestion .consulter, .user-gestion .consulter{
        text-align: center;
    }

    .commande-gestion > tbody > tr, .user-gestion > tbody > tr{
        box-shadow: black 2px 1px 18px 0px;
        display: block;
        border: none;
        margin: 20px 0;
    }

    .commande-gestion .btn, .user-gestion .btn{
        margin: 0;
    }

    .commande-gestion thead , .user-gestion thead{
        display: none;
    }

    .contact{
        flex-direction: column;
    }

    .montage, .explication, .conseil, .nav-footer {
        flex-direction: column;
    }

    .forfait{
        flex-direction: column;
    }

    .forfait div{
        width: 80%;
        margin: 20px auto;
    }

    .contact-footer{
        margin: 0;
    }

    .montage img{
        width: 100%;
        height: auto;
    }

    .montage div{
        padding: 0;
    }

    #mapcontact{
        width: 100%;
    }

    .filtre-boutique{
        width: 100%;
        padding: 0;
    }

    .prix-unitaire, .total-ht{
        display: none !important;
    }
}

@media screen and (max-width: 700px) {
    .filtre-boutique{
        display: none;
    }

    #afficher-filtre{
        display: block;
    }

    .ouverture > div{
        width: 90%;
        margin: auto;
    }

    .ouverture h3{
        padding: 0;
    }

    .horaire-adresse{
        flex-direction: column;
    }

    .liste-pneus{
        flex-direction: column;
    }

    .liste-pneus .filtre-boutique{
        width: 100%;
    }

    .recherche .filtre-pneus form{
        flex-direction: column;
    }

    .recherche .filtre-pneus form div{
        display: flex;
        justify-content: space-evenly;
        margin: 5px 0;
    }

    .recherche .filtre-pneus{
        width: 300px;
    }

    .pagination{
        width: 60%;
    }

    .th-total-ttc, .th-quantite{
        display: none;
    }

    .total-ttc, .td-quantite{
        display: table-row;
        border: none;
    }

    .td-quantite input{
        margin: 80px 20px 0;
    }

    #spanlargeur{
        left: 29%;
    }
    
    #spanslash{
        left: 37%;
    }
    
    #spanhauteur{
        left: 39%;
    }
    
    #spanR{
        left: 45%;
    }
    
    #spandiametre{
        left: 48%;
    }
    
    #spancharge{
        left: 56%;
    }
    
    #spanvitesse{
        left: 62%;
    }
}

@media screen and (max-width: 550px) {
    .form{
        width: 70%;
    }

    .form-1 div{
        width: 85%;
    }

    .filtre-boutique form{
        flex-direction: column;
    }

    .filtre-boutique form div{
        display: flex;
        justify-content: space-evenly;
        margin: 5px 0;
    }

    .panier{
        width: 100%;
    }

    .panier tr:last-child td:nth-child(7){
        border-bottom: none;
    }
    
    .panier tr td{
        border-right: rgb(135 135 135 / 56%) solid 1px;
    }

    .panier thead{
        display: none;
    }

    .panier tr td{
        display: table-row;
    }

    .panier tr td p{
        width: 85vw;
    }

    .panier .del{
        display: table-cell;
    }

    .td-img{
        height: 100px;
    }

    .td-img img{
        margin: 0;
    }

    .td-img i{
        margin-top: 50px;
    }

    .total-panier{
        width: 100%;
    }

    .td-quantite input{
        margin: 16px 0;
    }

    .commande tr td p{
        width: 96vw;
    }
   
    .commande tr td{
        border-right: none;
    }

    .apercu-image-actuel{
        width: 100%;
    }
}